import { Home } from 'components/pages/Home/Home';
import { EntryPoint, NextPageWithEntryType } from 'utils/nextTypes';

const HomePage: NextPageWithEntryType = function HomePage() {
  return <Home />;
};

HomePage.entryPointType = EntryPoint.PROTECTED;

export default HomePage;
